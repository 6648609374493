.loc {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 9px;
}

.About {
  display: flex;
  justify-content: center;
}
