.wrapper-home {
  display: flex;
  justify-content: center;
}
.title-home {
  text-align: center;
}

.homepara {
  text-align: justify;
  width: 40%;
  margin-right: 18px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 16px;
  line-height: 1.75;
  font-family: "Merriweather";
}

.text-align {
  text-align: justify;
}
