//=================================================
// Dropdown
//=================================================

/*=============================
// Default
=============================*/
// Toggle
.dropdown-toggle {
	@extend .bold;

	&:after, &:before {
		@extend .transition-transform;
	}
}

.show {
	> .dropdown-toggle {
		&:after, &:before {
			@include transform(rotate(-180deg));
			@extend .transition-transform;
		}
	}
}

// Hover
.dropdown-hover {
	position: relative;
}

// Click
.dropdown-click {
	position: relative;
}

// Menu
.dropdown-menu {
	background-clip: inherit;

	li {
		margin-bottom: 0;

		&:before, &:after {
			display: none;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.dropdown-toggle {
		&:before, &:after {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			right: $dropdown-item-padding-x;
		}

		&.active {
			&:before, &:after {
				@include transform(translateY(-50%) rotate(-180deg));
			}
		}
	}

	.dropdown-icon {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: $dropdown-item-padding-x;
	}
}

// Submenu
.dropdown-submenu {
	&.show {
		> .dropdown-custom-icon {
			color: $dropdown-link-active-color;
		}
	}

	> .dropdown-item {
		@extend .bold;
	}
}

// Header
.dropdown-header {
	margin-bottom: $dropdown_item_padding_y !important;
}

// Item
.dropdown-item {
	a {
		color: $dropdown_link_color;
	}
}

/*=============================
// Dropdown custom icon
=============================*/
.dropdown-icon {
	cursor: pointer;
	padding-left: 0.263rem;

	[class*='fa-'] {
		@extend .transition-transform;
	}
}

.dropdown-custom-icon {
	cursor: pointer;

	&.dropdown-toggle {
		&:before, &:after {
			display: none;
		}
	}

	&.active {
		> .dropdown-icon {
			[class*='fa-'] {
				@include transform(rotate(-180deg));
				@extend .transition-transform;
			}
		}
	}
}

.show {
	> .dropdown-custom-icon {
		.dropdown-icon {
			[class*='fa-'] {
				@include transform(rotate(-180deg));
				@extend .transition-transform;
			}
		}
	}
}
