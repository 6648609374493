//=================================================
// List group
//=================================================

// List group item unstyled
.list-group {
	margin-left: 0;

	.list-group-item, [class*="icon-list-item"] {
		&:before, &:after {
			display: none;
		}
	}
}

.list-group-item-action {
	&.active {
		h1, h2, h3, h4, h5, h6 {
			color: $white !important;
		}
	}
}
