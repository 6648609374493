//=================================================
// Sticky
//=================================================

.sticky-fixed {
    &-md {
        position: fixed;
        top: 0;
        overflow: scroll;
        height: 100vh;
        
        @media #{$media_md} {
            position: static;
            height: auto;
        }   
    }

    &-footer {
        position: relative !important;
        float: right;
    }
}
