.bigcontain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bord {
  border-bottom: 3px solid red;
}

.wrap {
  display: flex;
}

.imgWidth {
  width: 200%;
}
