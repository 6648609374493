//=================================================
// Clients
//=================================================

.client-items {
  border-left: 1px solid $body_border_color;
  border-top: 1px solid $body_border_color;

  .client-item {
    width: 20%;
    float: left;
    position: relative;
    text-align: center;
    border-right: 1px solid $body_border_color;
    border-bottom: 1px solid $body_border_color;

    @media #{$media_xl} {
      width: 25%;
    }

    @media #{$media_md} {
      width: 33.33%;
    }

    @media #{$media_sm} {
      width: 50%;
    }

    .client-body {
      width: 100%;
      height: 0;
      padding-top: 0;
      padding-bottom: 100%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        max-width: 100%;
        max-height: 10.895rem;
        padding: 2.632rem;
      }
    }
  }
}

.client-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

// .box {
//   height: 180px;
//   width: 400px;
//   margin: 10px -400px;
//   animation-name: move;
//   animation-duration: 7s;
//   animation-iteration-count: infinite;
//   animation-direction: right;
//   animation-timing-function: linear;
// }

// #box:hover {
//   animation-play-state: paused;
// }

@keyframes move {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 800px;
  }
}
